import BGImage from "./Assets/images/background.webp"
import Facebook from "./Assets/images/icons/facebook.svg"
import Instagram from "./Assets/images/icons/instagram.svg"
import Whatsapp from "./Assets/images/icons/whatsapp.svg"
import Logo from "./Assets/images/logo.svg"
import styled from "styled-components";
import { useEffect, useState } from "react"

function App() {
  const [info, setInfo] = useState(false);

  useEffect(() => {
    fetch(`arquivos/arquivos.json`)
      .then((r) => r.json())
      .then((data) => {
        setInfo(data);
      })
  }, []);

  return (
    <div className="App">
      {info &&
        <Background>
          <Card>
            <Flex>
              <div>
                <H1 dangerouslySetInnerHTML={{ __html: info?.title }}></H1>
                <H3 dangerouslySetInnerHTML={{ __html: info?.text }}></H3>
                <div className="buttons">
                  {info?.buttons?.map(button => {
                    if (button?.files?.length > 1) {
                      return (
                        <select onChange={(e) => { window.open(e.target.value, '_blank', 'noopener,noreferrer'); e.target.value = "" }}>
                          <option value="" disabled selected>{button.name}</option>
                          {button.files.map(file => <option value={file.path}>{file.name}</option>)}
                        </select>
                      )
                    } else {
                      return (
                        <a href={button?.files[0]?.path} target={"_blank"} rel="noopener noreferrer">
                          <Button>{button.name} {button?.files[0]?.name}</Button>
                        </a>
                      )
                    }
                  })}
                </div>
                <div className="socials">
                  <a href={info?.sociais?.instagram} target={"_blank"} rel="noopener noreferrer">
                    <img src={Instagram} alt="Instagram" />
                  </a>
                  <a href={info?.sociais?.facebook} target={"_blank"} rel="noopener noreferrer">
                    <img src={Facebook} alt="Facebook" />
                  </a>
                  <a href={info?.sociais?.whatsapp} target={"_blank"} rel="noopener noreferrer">
                    <img src={Whatsapp} alt="Whatsapp" />
                  </a>
                </div>
              </div>
              <img src={Logo} alt="APAE Santa Cruz das Palmeiras" style={{ maxWidth: "100%" }} />
            </Flex>
            <p>
              <b>Rua:</b> {info?.contato?.endereco} - {info?.contato?.bairro} - <b>CEP</b> {info?.contato?.cep} | <b>Fone</b> {info?.contato?.telefone}<br />
              <b>E-mail:</b> {info?.contato?.email}<br />
              <b>CNPJ:</b> {info?.contato?.cnpj}<br />
              {info?.contato?.adicional}
            </p>
          </Card>
        </Background>
      }
    </div>
  );
}

export default App;


const Background = styled.div`
    background: url(${BGImage});
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Card = styled.div`
  box-shadow: -18px 19px 7px -3px rgba(0, 0, 0, 0.25);
  border-radius: 63px;
  background-color: white;
  width: 1100px;
  max-width: 100vw;
  height: 700px;
  max-height: 100vh;
  padding: 80px 100px;
  overflow-x: hidden;
  overflow-y: auto;

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #000000;

    opacity: 0.56;
    margin: 0;
    margin-top: 50px;
  }

  @media(max-width: 991px) {
    border-radius: 25px;
    height: 95vh;
    width: 95vw;
    padding: 20px;

    > p {
      font-size: 13px;
      line-height: 16px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    select {
      background: #5FAC4A;
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.025em;
      color: #FFFFFF;
      border:0;
      padding: 13px 10px;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      &:hover {
        filter: brightness(.9);
      }

      &::-ms-expand {
        display: none;
      }

      option {
        background: #eee;
        font-size: 14px;
        font-weight: 400;
        text-align:center;
        color: #555;

        &:first-child {
          display: none;
        }
      }
    }
  } 

  .socials {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 35px
  }

  @media(max-width: 991px) {
    flex-direction: column-reverse;

    > img {
      max-height: 40vw;
    }

    .buttons {
      flex-direction: column;
    }
  }
`;

const H1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 10px 100px;
  background: #FBB800;
  border-radius: 8px;
  
  @media(max-width: 991px) {
    padding: 10px;
    width: 100%;
    font-size: 22px;
    line-height: 28px;
  }
`;

const H3 = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #000000;

  @media(max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Button = styled.button`
  background: #5FAC4A;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  border:0;
  padding: 13px 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(.9);
  }
`